import Cookies from 'js-cookie'
const TokenKey = 'token'
export function getToken() {
  return Cookies.get(TokenKey)
}
export function getExpires() {
  return Cookies.get('time')
}
export function setToken(token) {
  return Cookies.set(TokenKey, token,{ expires: 2 })
}
export function setWebInfo(info) {
  return Cookies.set('setWebInfo', info)
}
export function getWebInfo() {
  return Cookies.get('setWebInfo')
}
export function setExpires(expires_in) {
  var timestamp = Date.parse(new Date())
  expires_in = timestamp/1000 + expires_in
  return Cookies.set('time', expires_in)
}
export  function removeToken() {
  Cookies.remove(TokenKey)
  return 'removeToken'
}






