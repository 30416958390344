/**
 * 更改 顶部标题title
 * */
import defaultSettings from '@/settings'

const title = defaultSettings.title || 'CSGO开箱网皮肤饰品'

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}
