import {request} from "./index";
async function userLoginToken(data){
    let {stast,verify} = data
    if(stast == 'login' && verify == ''){
        return request({
            url: `/login`,
            method:'post',
            data
        })
    }
    if(stast == 'register'){
        return request({
            url: `/register`,
            method:'post',
            data
        })
    }
    if(stast == 'forget'){
        return request({
            url: `/reset_password`,
            method:'post',
            data
        })
    }

}
function getSendSms(data) {
    return request({
        url: `/send_sms`,
        method:'post',
        data
    })
}
function getUsetInof() {
    return request({
        url: `/me`,
        method:'get'
    })
}
function getWebInfo() {
    return request({
        url: `/info`,
        method:'get'
    })
}

function getRefreshToken(){
    return request({
        url: `/auth/refresh`,
        method:'post'
    })
}
function get_bind_mobile(data){
    return request({
        url: `/bind_mobile`,
        method:'post',
        data
    })
}

export default {
    userLoginToken,
    getSendSms,
    getUsetInof,
    getWebInfo,
    getRefreshToken,
    get_bind_mobile
}
