module.exports = {

  title: '52skins开箱网|CSGO饰品即开即取|高爆率开箱体验',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false
}
