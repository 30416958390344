<template>
  <div class="zm-imgbox">
    <div class="zm-img">
      <img src="@/assets/img2/zmbg.png" alt="">
    </div>
    <div class="row m-0 p-0 flex-wrap" :class="!isShowSwiperOff ? '' : 'swiper'"
      style="border-top: 1px solid rgba(255, 255, 255, 0.5); height: 100px;  ">
      <div class="swiper-list row m-0 p-0">
        <div class="swiper-list-content d-flex position-relative" @click="warp($event)"
          style="margin-top: 65px !important;padding-left: 40px;padding-right: 40px;">
          <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp overflow-hidden"
            style="height: 90px">
            <ul class="" style="display: flex">
              <!-- 循环 -->
              <li v-for="(item, index) in listData" :key="index + item" class="position-relative swiper-list-content-box">
                <a :href="item.type | openUrl(item)"
                  class="d-flex position-relative swiper-list-content-box-item overflow-hidden"
                  :class="`swiper-bg-${item.lv || 1}`">
                  <div class="bg-size-100 bg-repeat w-100">
                    <div class="align-items-center px-1 d-flex position-relative justify-content-center">
                      <img style="z-index: 99; height: 50px" :src="item.cover" alt="" class="position-relative m-auto" />
                    </div>
                    <div class="ml-2">
                      <div class="px-2 text-overflow-1 text-center" style="font-size: 12px">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </a>

                <a :href="item.type | openUrl(item)"
                  class="position-relative swiper-list-content-box-item overflow-hidden d-flex">
                  <div class="bg-size-100 bg-repeat w-100 d-flex justify-content-center align-items-center flex-column">
                    <div class="align-items-center d-flex position-relative mb-2">
                      <img class="rounded-pill" width="50px" height="50px" :src="item.get_user.avatar" alt="" />
                    </div>
                    <div class="ml-2">
                      <div class="fon14 text-overflow-1">
                        {{ item.get_user.name }}
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <!-- end循环 -->
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>

      <el-dialog custom-class="login_egister" :show-close="false" :close-on-press-escape="false"
        :close-on-click-modal="false" :visible.sync="login_is_show" :top="'0'" width="530px"
        style="backdrop-filter: blur(3px);">
        <div class="login">
          <div class="text-center text-white font-weight-bold">
            <img style="width: 180px; height: 70px; margin-top: 30px" src="@/assets/img/login-logo.png" alt="" />
          </div>
          <div class="pb-5">
            <!-- 老登录注册 -->
            <!-- <div
              class="text-center mt-3 text-white d-flex justify-content-around w-50 m-auto"
            >
              <span
                @click="loginUser('login')"
                class="cursor-pointer h5 text-white font-weight-bold"
                style="margin-left: 2px"
                >登录
              </span>
              <span
                @click="loginUser('register')"
                class="cursor-pointer h5 text-white font-weight-bold"
                style="margin-left: 2px"
                >注册
              </span>
            </div> -->
            <div v-if="tabLoginRF == 'login'" class="welcome family">欢迎回来！</div>
            <div class="mx-3">
              <p v-if="tabLoginRF == 'login'" style="margin-top: 16px; color: #fff">
                账号：
              </p>
              <div class="d-flex border-dark-brown align-items-center">
                <div>
                  <input v-model="form.mobile" class="text-white" style="font-size: 16px" type="text"
                    placeholder="请输入您的账号" />
                </div>
              </div>
              <!-- <div v-if="tabLoginRF != 'login' || form.steam_data != '' || code == true
                " class="d-flex border-dark-brown align-items-center">
                <div>
                  <input v-model="form.verify" class="text-white code-phone" style="font-size: 16px" type="text"
                    placeholder="请输入验证码" />
                </div>
                <div @click="getCode" :class="getCodeSeond != '获取验证码'
                  ? 'cursor-not'
                  : 'cursor-pointer'
                  " class="text-center px-1 ml-auto code-phone-size" style="
                    height: 42px;
                    line-height: 40px;
                    width: 200px;
                    color: #fff;
                  ">
                  <div class="">
                    {{ getCodeSeond }}
                  </div>
                </div>
              </div> -->
              <p v-if="tabLoginRF == 'login'" style="margin-top: 16px; color: #fff">
                密码：
              </p>
              <div v-if="form.steam_data == '' && code == false" class="d-flex border-dark-brown align-items-center">
                <div>
                  <input @keyup.enter="login" v-model="form.pass" class="text-white" style="font-size: 16px"
                    type="password" placeholder="请输入密码" />
                </div>
              </div>
              <div v-if="tabLoginRF == 'register'" class="d-flex border-dark-brown align-items-center">
                <div>
                  <input v-model="form.name" class="text-white" style="font-size: 16px; width: 100%" type="text"
                    placeholder="请输入用户名" />
                </div>
              </div>

              <!-- <div v-if="tabLoginRF == 'register'" :class="tabLoginRF == 'register' ? 'w-100' : ''"
                class="d-flex border-dark-brown align-items-center">
                <div>
                  <input v-model="form.invite" class="text-white" style="font-size: 16px" type="text"
                    placeholder="请输入邀请码（选填）" />
                </div>
              </div> -->
              <div v-if="tabLoginRF != 'login' || form.steam_data != '' || code == true
                " class="d-flex border-dark-brown align-items-center">
                <div>
                  <input v-model="form.verify" class="text-white code-phone" style="font-size: 16px" type="text"
                    placeholder="请输入验证码" />
                </div>
                <div @click="getCode" :class="getCodeSeond != '获取验证码'
                  ? 'cursor-not'
                  : 'cursor-pointer'
                  " class="text-center px-1 ml-auto code-phone-size" style="
                    height: 42px;
                    line-height: 40px;
                    width: 200px;
                    color: #fff;
                  ">
                  <div class="">
                    {{ getCodeSeond }}
                  </div>
                </div>
              </div>
              <div v-if="tabLoginRF == 'register'" :class="tabLoginRF == 'register' ? 'w-100' : ''"
                class="d-flex border-dark-brown align-items-center">
                <div>
                  <input v-model="form.invite" class="text-white" style="font-size: 16px" type="text"
                    placeholder="请输入邀请码（选填）" />
                </div>
              </div>
              <div class="d-none">
                <input v-model="form.steam_data" type="text" />
              </div>
            </div>

            <div @click="loginUser('forget')" class="text-right text-white cursor-pointer" style="margin:15px 0;">
              忘记密码
            </div>
            <!-- 登录按钮 -->
            <div class="d-flex justify-content-between">
              <div @click="login"
                class="ml-auto f-main-bg d-flex align-items-center justify-content-center cursor-pointer login-bnt">
                {{ tabLoginRFName }}
              </div>
            </div>
            <div>
              <div class="goLogin">
                <span v-if="tabLoginRF == 'register'" class="cursor-pointer" @click="loginUser('login')">去登录</span>
                <span v-if="tabLoginRF == 'login'" class="cursor-pointer" @click="loginUser('register')">去注册</span>
              </div>
            </div>

            <div @click="off_login" class="position-absolute" style="right: 30px; top: 20px">
              <div style="font-size: 28px">
                <i class="iconfont text-white el-icon-switch-button cursor-pointer" style="font-size: 28px"></i>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

    </div>
    <!--  公告  -->
    <!-- <div class="swiper-top" style="margin-top: 60px;background-color: hsla(0, 0%, 0%, 0);">
      <div class="pr-1">
        <img style="width: 12px" src="../assets/img/laba.png" alt="" />
      </div>
      <marquee
        :scrollamount="scrollamount"
        class="swiper-top-marquee"
        width="100%"
        behavior="scroll"
      >
        {{ web_info.site_bulletin }}
      </marquee>
    </div> -->
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { setToken, setExpires } from "@/utils/auth";
import $api from "@/api/login.js";
import $api_history from "@/api/CSGO/index.js";
import { mapState } from "vuex";

export default {
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      scrollamount: 6,
      listData: [],
      classOption: {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 15, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      loginRegisterShow: false,
      tabLoginRF: "login",
      getCodeSeond: "获取验证码",
      code: false,
      agreement: true,
      form: {
        mobile: "",
        pass: "",
        // name: '',
        verify: "",
        invite: "",
        steam_data: "",
        password: "",
        bd_vid: "",
      },
      checked: false,
      isShowSwiperOff: false,
    };
  },
  filters: {
    openUrl(ty, item) {
      switch (ty) {
        case 1: // 开箱
          return "/active/" + item.box_id;
        case 2: // 福利箱
          return "/active/outOfTheFree/" + item.box_id;
        case 3: // 盲盒对战
          return "/mysteryBox";
        case 4: // 幸运开箱
          return "/lucky";
        case 5: // 饰品商城
          return "/shopping";
        case 6: // 夺宝
          return "/";
        case 7: // 装备合成
          return "/compound";
      }
    },
  },
  created() {
    let invite = this.$route.query.ref_code;
    if (invite) {
      this.form.invite = invite;
      if (this.user_info) {
        this.loginRegisterShow = false;
      } else {
        this.loginRegisterShow = true;
      }
    }
    this.init();
  },
  computed: {
    ...mapState(["user_info", "steam_handle", "login_top", "web_info"]),
    tabLoginRFName: function () {
      if (this.tabLoginRF == "login") {
        return "登录";
      }
      if (this.tabLoginRF == "register") {
        return "注册";
      }
      return "找回密码";
    },
    login_is_show() {
      return this.loginRegisterShow || this.login_top;
    },
  },
  mounted() {
    let codeRef = window.sessionStorage.getItem("codeRef");
    if (codeRef) {
      this.form.invite = codeRef;
    }
    let ua = window.sessionStorage.getItem("ua");
    if (ua == "phone") {
      this.scrollamount = 2;
    }
  },
  methods: {
    isShowSwiper() {
      this.isShowSwiperOff = !this.isShowSwiperOff;
    },
    async init() {
      this.form.steam_data = this.steam_handle;
      await this.getBoxHistory();
    },
    warp(event) {
      console.log(event);
      return;
    },
    loginRegister() {
      this.loginRegisterShow = true;
      this.$store.commit("LOGIN_TOP", true);
    },
    off_login() {
      this.tabLoginRF = "login";
      this.loginRegisterShow = false;
      this.$store.commit("LOGIN_TOP", false);
      this.$store.commit("LOGIN_TOP_IS_XIE_YI", false);

      if (this.$route.name == "nologin") {
        this.$router.push("/");
      }
    },
    loginUser(start) {
      this.tabLoginRF = start;
      if (start == "register") {
        this.agreement = false;
      } else {
        this.code = false;
        this.agreement = true;
      }
    },
    async getCode() {
      const reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      if (this.form.mobile == '') {
        this.$toast.fail("请先输入手机号");
        return
      } else if (!reg.test(this.form.mobile)) {
        this.$toast.fail("手机格式不正确");
        return
      }

      let is_use = 1;
      if (this.tabLoginRF == "register") {
        is_use = 1;
      } else {
        is_use = 2;
      }
      this.getCodeSeond = 60;
      let data = await $api.getSendSms({ mobile: this.form.mobile, is_use });
      if (data.success) {
        // this.$notify({
        //   message: data.message,
        // });
      } else {
        // this.$notify({
        //   message: data.message,
        // });
      }
      let time = setInterval(() => {
        this.getCodeSeond -= 1;
        if (this.getCodeSeond == 0) {
          this.getCodeSeond = "获取验证码";
          clearInterval(time);
        }
      }, 1000);
    },
    agreementClick() {
      this.agreement = !this.agreement;
    },
    async login() {
      this.$store.commit("LOGIN_TOP_IS_XIE_YI", false);
      this.form.stast = this.tabLoginRF;
      this.form.password = this.form.pass;
      this.form.bd_vid = window.location.href;
      window.localStorage.setItem("popularize", window.location.href);
      let { code, data, message } = await $api.userLoginToken(this.form);
      if (code != 200) {
        return;
      }
      if (this.tabLoginRF == "forget") {
        this.tabLoginRF = "login";
        this.form.verify = "";
        this.$notify({
          message: message,
          type: "success",
          position: "top-left",
        });
        return;
      }
      setToken(data.access_token);
      setExpires(data.expires_in);
      this.$store.dispatch("userInfo");

      this.off_login();
      this.$store.commit("LOGIN_IS_SHOW", false);

      if (this.$route.name == "CSGOActive") {
        this.$router.go(0);
      }
      if (this.$route.name == "nologin") {
        this.$router.go(0);
      }
    },
    async getBoxHistory() {
      try {
        let { data } = await $api_history.getBoxHistory();
        this.listData = data;
        setInterval(() => {
          $api_history.getBoxHistory().then((res) => {
            let listData = this.listData.findIndex((item) => {
              return res.data.findIndex((it) => {
                return item.id == it.id;
              });
            });
            this.listData.push(...res.data.slice(0, listData + 5));
            this.listData.splice(0, listData + 5);
          });
        }, 5000);
      } catch (e) {
        console.log(e);
      }
    },
    ligin_zhuce() {
      this.loginRegisterShow = false;
      this.$store.commit("LOGIN_TOP", false);
      setTimeout(() => {
        this.$router.push("/active/help/2");
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
.zm-imgbox{
  position: relative;
}
.zm-img {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100px;
  margin-top: 30px;
  @media (min-width:720px) {
    width: 450px;
    margin-top: 50px;
  }
  img {
    width: 100%;
    height: inherit;
    @media (min-width:450px) {
    width: 450px;
  }
  }

}

.heaght_0 {
  height: 0 !important;
}

.info {
  border-top: 1px solid var(--f-main);
}

.info-swiper {
  margin: 0 auto;
  width: 150px;
  padding: 2px 34px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  line-height: 1.3rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--f-main);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 15% 100%);
}

.content-hove:hover {
  color: #ffffff;
}

.f_login {
  background-color: #e94d8b;
  color: #ffffff;
}

.code-phone {
  @media (max-width: 1200px) {
    width: 100px !important;
  }

  &-size {
    @media (max-width: 1200px) {
      font-size: 12px;
    }
  }
}

.login {
  &-right {
    width: 100%;

    &-input {
      color: #ffffff;
      font-size: 25px;
    }
  }
}

.swiper {
  width: 100%;
  height: auto !important;

  @media (max-width: 1000px) {
    height: 80px !important;
  }

  &-top {
    width: 80%;
    margin: 5px auto;
    padding: 5px 10px;
    background-color: var(--main-bg-2);
    border-radius: 15px;
    display: flex;
    align-content: center;
    font-size: 14px;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  &-user {
    width: 200px;
    height: 75%;
    font-size: 16px;

    & svg {
      font-size: 40px;
    }

    &-box {
      text-align: center;
      padding: 4px 0;
    }
  }

  &-list {
    width: 100%;

    @media (max-width: 1000px) {
      transform: scale(1, 0.7) translateY(-15%);
    }

    &-content {
      width: 100%;

      &-box {
        top: 0;
        transition: all 0.5s;
        border-left: 2px solid var(--f-main);

        &:hover {
          top: -90px;
        }

        &-item {
          transition: all 0.5s;
          width: 120px;
          height: 90px;
          flex-direction: column;
          justify-content: center;

          @media (max-width: 1000px) {
            width: 120px;
          }

          /*background-color: var(--main-bg-2);*/
          /*&:nth-child(1)::before {*/
          /*  content: '';*/
          /*  width: 30px;*/
          /*  height: 30px;*/
          /*  position: absolute;*/
          /*  top: 0;*/
          /*  right: 0;*/
          /*  z-index: 9999;*/
          /*  background-size: 100% 100%;*/
          /*  background-image: url("../assets/img/f_new/jiangli.png");*/
          /*}*/
        }

        &-itemnone {
          opacity: 0;
          transition: all 0.5s;
          width: 170px;
          height: 80px;

          &-title {
            text-align: center;
            padding: 10px 0;
          }

          &-img {
            transition: all 1s;
          }
        }
      }
    }
  }
}

.fon14 {
  font-size: 14px;
}

.swiper {
  height: 300px;
  width: 100%;
  border: 1px transparent solid;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    background-color: rgb(98, 168, 200);
  }
}

.ceshi {
  transform-origin: 100% 50% 0;
  -webkit-animation: scrollAn 0.65s ease;
  animation: scrollAn 0.65s ease;
}

@keyframes scrollAn {
  0% {
    margin-left: -130px;
    opacity: 0;
    transform: perspective(800px) rotateY(-90deg) translateZ(0);
  }

  100% {
    margin-left: 1px;
    opacity: 1;
    transform: perspective(800px) rotateY(0deg) translateZ(0);
  }
}

input {
  width: 100%;
  height: 42px;
  color: white;
}

// 设置input框中placehold颜色
input::placeholder {
  color: white;
}

.goLogin {
  color: #9fda35;
  font-size: 20px;
  text-align: center;
  margin-top: 15px;
}

.welcome {
  font-size: 25px;
  margin-top: 10px;
  color: white;
  padding-left: 25px;
}

.login-bnt {
  width: 300px;
  margin: auto !important;
  height: 45px;
  border-radius: 5px;
  background-color: #83b8ca;
  font-size: 16px;
}
</style>
