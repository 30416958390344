const axios = require('axios')
import { getToken } from '@/utils/auth'
import md5 from 'js-md5'
import Notification from "element-ui/packages/notification/src/main";
// import { Message } from 'element-ui';
import store from '../store'
import router from '../router'
import { removeToken } from '@/utils/auth'

export function request(config) {
    let apiurl = ''
    if(process.env.NODE_ENV === 'development'){
        apiurl = 'https://api.mocsgo.com/api';
        //apiurl = `https://api.mocsgo.com/api`;
        // apiurl = 'https://api.mocsgo.com/api';
    }else {
        apiurl = 'https://api.mocsgo.com/api'
    }
    const instance = axios.create({
        baseURL: apiurl,
    })

    instance.interceptors.request.use(config => {
        /**
        */
        let token = getToken()
        let time = (Date.parse(new Date()) / 1000)
        config.headers = {
            'Authorization' : 'Bearer ' + token || '',
            'token': md5('a8a4f6e4744b1559726f6e5bf8b58253' + time),
            'timestamp': time
        }
        return config
    },err => {
        return err.data
    })

    instance.interceptors.response.use(res => {
        if(res.data.code == 500 ){
            Notification.closeAll()
            Notification.error({
                message: res.data.message,
                position: 'top-left'
            });
        }else if(res.data.code == 401){
            store.commit("LOGIN_IS_SHOW", true)// 显示登录按钮
            store.commit('USER_INFO',{}) // 将user的信息清空
            removeToken()// 删除token
            router.push('/MUnologin') // 跳转到登录空白页
            return res.data
        }
        return res.data
    },err => {
        return err.data
    })

    return instance(config)
}
