import $api from './pk/index.js'
import Vue from "vue";
import $common from "@/assets/js/common.js";
let apiurl = 'ws://api.demo628.aaskins.cn/wss'
//let apiurl = 'ws://api3.52skins.com/wss'
let ws = new WebSocket(apiurl);
Vue.prototype.$ws = ws
ws.addEventListener('open',()=>{
    ws.send('你好')
})
ws.addEventListener('message', function (event) {
    let data = event.data;
    let {Data, MsgType} = JSON.parse(data)
    if (MsgType == 'connect') {
        let md5jiamiObj = $common.md5jiami(Data.client_id)
        let websocket = {
            MsgType: 'auth',
            token: md5jiamiObj.md5Token,
            string: md5jiamiObj.randomStr,
            time: md5jiamiObj.tiem,
            app_id: 'siyecao'
        }
        ws.send(JSON.stringify(websocket))
        $api.getArenaJoinChannel({client_id:Data.client_id})
    }
});
ws.onerror = function (e) {
    console.log('WebSocket发生错误: ' + e.code)
    console.log(e)
  }
setInterval(()=>{
    ws.send('y')
},5000)
