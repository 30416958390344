<template>

            <span class="money" :style="'color:' + color + ';font-size:' +  fontSize +  ';'"
                 >
              <img v-if="integral" src="@/assets/img2/sy-slices/切图 32.png" style="width: 100%;height: 100%" alt="">
              <img v-else src="@/assets/img2/sy-slices/导航栏_钻石图标.png" style="width: 100%;height: 100%" alt="">
            </span>
</template>

<script>
    export default {
        name: "money",
        data() {
            return {}
        },
        props:{
            color:{
                type:String,
                default:'var(--main-yellow)'
            },
            fontSize:{
                type: String,
                default: '18px'
            },
            integral:{
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style scoped>
.money{
        margin-right: 5px;
            width: 15px;
            height: 15px;
            display: inline-block
    }
</style>
