import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './api/socket'
import money from './common/money/money'
import vacancy from './common/vacancy/vacancy'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';
import VueCropper from 'vue-cropper'
import common from './assets/js/common'
import Moment from 'moment'
import 'vant/lib/index.css';
import "amfe-flexible/index.js"
import { Field,Icon,Uploader,Toast} from "vant"


Vue.use(Field)
Vue.use(Icon)
Vue.use(Uploader)
Vue.use(Toast)


Vue.filter('formatDate', function(value) {
  return Moment(value).format('YYYY-MM-DD HH:mm:ss')
})
import LuckDraw from 'vue-luck-draw'
Vue.use(LuckDraw)

import draw from 'draw-lottery'
Vue.use(draw)

import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload,{
  loading:require('@/assets/img/loading-bars.svg'),
  error:require('@/assets/img/shandian_ceshi.png'),
})

Vue.prototype.$common = common
Vue.use(ElementUI);
Vue.component('money', money)
Vue.component('vacancy', vacancy)
Vue.use(VueCropper)


Vue.config.productionTip = false
import '@/permission'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
