<template>
  <div class="app d-flex">
    <header-top class="z-index-99 bgcolor"></header-top>
    <div style="margin-top: 56px; width: 100%" class="" id="content">
      <swiper-user></swiper-user>  <!-- v-if="isShowSwiperOff" -->
      <transition name="fade-transform" mode="out-in">
        <router-view :key="key" />
      </transition>
      <footer-botton></footer-botton>
    </div>
    <div class="common_bg" @click="bodyCilkck"></div>
    <silde class="d-md-block"></silde>

    <div class="position-fixed TaskIn d-none">
      <Task-in :bottom-right="false">
        <div
          class="cursor-pointer silde-serve silde-W d-flex flex-column justify-content-center align-items-center"
        >
          <img width="50%" :src="require('@/assets/img/silde/QD.png')" alt="" />
          <div>签到</div>
        </div>
      </Task-in>
    </div>
  </div>
</template>

<script>
import headerTop from "./headerTop.vue";
import swiperUser from "./swiperUser.vue";
import footerBotton from "./footerBotton";
import silde from "./silde";
import TaskIn from "@/views/TaskIn/index";
import $api from "@/api/login.js";
import { setWebInfo } from "@/utils/auth";
export default {
  name: "layout",
  components: {
    headerTop,
    swiperUser,
    footerBotton,
    silde,
    TaskIn,
  },
  data() {
    return {
      routers: [],
      isShowSwiperOff: true,
      key: this.$route.path,
      lunbo:0,
    };
  },

  computed: {},
  created() {
    this.routers = this.$router.options.routes[0].children;
  },
  mounted() {
    $api.getWebInfo().then((res) => {
      setWebInfo(res.data);
      this.$store.commit("WEB_INFO", res.data);
    });
  },
  // 监听,当路由发生变化的时候执行
// watch:{
//   $route(to,from){
//     if(to.path == "/"){
//       this.isShowSwiperOff = true
//     }else{
//       this.isShowSwiperOff = false
//     }
//     console.log(to.path);
//   }
// },
  methods: {
    bodyCilkck() {
      this.$store.commit("ISSHOWNAVPC", false);
    },
  },
  // watch: {
  //   $route(to, from) {
  //     if (to.path != "/") {
  //       this.lunbo = 2;
  //     }
  //     if (to.path == "/") {
  //       this.lunbo = 1;
  //     }
  //   },
  // },
};
</script>

<style scoped lang="scss">
.TaskIn {
  right: 0;
  bottom: 60%;
  z-index: 999;
  // display: none;
}
.bgcolor {
  background-color: #05377b;
}
#content {
  /*margin-left: 250px;*/
  height: calc(100vh - 70px);
  overflow-y: scroll;
  overflow-x: hidden;

  /* 手机端放大效果 */
  @media (max-width: 1000px) {
    height: auto;
    overflow-y: auto;
    margin-bottom: 60px;
  }

  /*&::-webkit-scrollbar {display:none}*/
}

.common_bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: -1;
  // opacity:0.5;
  background-color: #05377b;
  // background-image: url('../assets/imgV2/lucky/bg.png');
  // background-size: 100% 50%;
  // background-repeat: no-repeat;
  // opacity:0.5;
  // background-position: center;
}
</style>
