<template>
    <div class="TaskIn">
       <div>
           <div @click="isShowTaskIn">
               <slot>
                   <div class="TaskIn-img" v-if="bottomBtn">
                       <img src="@/assets/img/taskIn/Blue_back.png" alt="">
                   </div>
               </slot>
           </div>
           <div v-if="bottomBtn" class="position-absolute top-0 left-0" @click="bottomBtn = false">
               <i class="iconfont icon-guanbi-02-02 h1" ></i>
           </div>
       </div>

        <div v-if="isShow" class="position-fixed TaskIn-con">

            <div class="TaskIn-con-top d-flex justify-content-center align-items-center bg-size-100"
                 :style="`background-image:url(${require('@/assets/img/drophistory.png')})`">
                <div>
                    任务签到
                </div>
            </div>
            <div class="TaskIn-con-bottom px-2 px-md-3">
                <div class="text-center text-white p-3 h4">开始签到吧 已连续签到 <span class="color-yellow h3">{{signDays.days}}</span> 天
                </div>
                <div style="font-size:14px;" class="text-center text-white pb-3" >
                    玩家连续签到7天，获得 <span class="color-yellow">2-20<money style="margin-left: 5px"/>币</span> 随机红包
                </div>
                <div class="d-flex px-md-3 flex-wrap justify-content-between">
                    <div v-for="(item,index) in 7" :key="index"
                         class="text-white d-flex flex-column justify-content-center align-items-center cursor-pointer">
                        <div>第{{index+1}}天</div>
                        <div class="TaskIn-con-bottom-yuan" :class="index+1 <= signDays.days? 'active' : ''">
                            <span :class="index === 0?'d-none':''"></span>
                        </div>
                    </div>

                </div>
                <div @click="getTask" class="TaskIn-btn mt-3 text-center cursor-pointer mx-auto text-white">
                    {{TaskTitle?'点击签到': signDays.days === 7? '签到并领取':"已签到"}}
                </div>

            </div>
            <div class="color-yellow position-absolute right-0 top-0" @click="isShow = false">
                <i class="el-icon-close color-yellow h4 border rounded-circle p-1"></i>
            </div>

        </div>
        <div class="bg" v-if="isShow" @click="isShow = false"></div>
    </div>
</template>

<script>
  import $api from '@/api/task/index'
  import {getToken} from '@/utils/auth'

  export default {
    name: "TaskInIndex",
      props:{
          bottomRight: {type:Boolean,default:true}
      },
    data() {
      return {
        isShow: false,
        TaskTitle: true,// 签到名
        signDays: {
          days: -1,
          is_receive: 0
        },

      }
    },
    components: {},
      computed:{
          bottomBtn:{
              get(){
                return this.bottomRight
              },
              set(){
                this.bottomRight = false
              }
          }
      },
    created() {
    },
    mounted() {
    //   this.init()
      let QD = window.sessionStorage.getItem('QD')
      if (QD == 'false') {
        this.TaskTitle = false
      }

    },
    beforeDestroy() {
    },
    methods: {
      
      isShowTaskIn() {
        let token = getToken()
        if (token) {
          this.isShow = true
          this.init()
        } else {
          this.$store.commit('LOGIN_TOP', true)
        }

      },
      // 点击签到
      getTask() {
          // 签到七天
          $api.getTask_sign_up().then(res => {
              if (res.code == 200) {
                this.TaskTitle = false
                window.sessionStorage.setItem('QD', false)
                this.init()
                this.$message.success(res.message)
              }
          })

          if(this.signDays.days >= 7){
             setTimeout(()=>{
                this.task_receive(1)
             },1000)
          }
      },
      receive(sum) {
        this.task_receive(sum)
      },
      task_receive(type = 1) {
        $api.task_receive({type: type}).then(res => {
          if (res.code == 200) {
            let {data} = res
            this.$alert('恭喜获得' + data.amount + '金币奖励', '获取奖励', {
              confirmButtonText: '确定',
              customClass:'pkClass'
            });
          } else {
            this.$alert(res.message, '提示', {
              confirmButtonText: '确定',
              customClass:'pkClass'
            });
          }
          this.init()
        })
      }
    },
  };
</script>

<style scoped lang="scss">
    .bg {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .8);
    }

    .TaskIn {
        &-img {
            width:160px;

            & img {
                width: 100%;
            }
        }

        &-con {
            width: 720px;
            top: 10vh;
            padding-bottom: 20px;
            /*bottom: 10vh;*/
            left: calc(50% - 360px);
            background-color: #21212a;
            border-radius: 20px;
            z-index: 1;
            @media (max-width: 1000px) {
                width: 96%;
                left: 2%;
            }

            &-top {
                width: 100%;
                height: 120px;
                font-size: 1.8rem;
                font-weight: bold;
                background-color: rgba(0,0,0,.2);
                border-bottom: 1px solid var(--main-blue);
            }

            &-bottom {
                color: #000;

                &-yuan {
                    width: 16px;
                    height: 16px;
                    border: 3px solid #b6dcff;
                    border-radius: 50%;
                    position: relative;
                    margin: 15px;

                    &::before {
                        content: '';
                        border-radius: 50%;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        background-color: #b6dcff;
                        left: calc(50% - 4px);
                        top: calc(50% - 4px);
                    }

                    & span {
                        content: '';
                        width: 40px;
                        height: 6px;
                        background-color: #b6dcff;
                        position: absolute;
                        right: 300%;
                        top: calc(50% - 4px);
                        @media (max-width: 1000px) {
                            display: none;
                        }
                    }
                }

                & .active {
                    border: 3px solid red;

                    &::before {
                        content: '';
                        border-radius: 50%;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        background-color: red !important;
                        left: calc(50% - 4px);
                        top: calc(50% - 4px);
                    }
                }

                &-win {
                    padding: 10px;
                    color: #ffffff;
                    font-size: 14px;
                    border-radius: 10px;
                }


            }
        }

        &-btn {
            width: 50%;
            padding: 20px;
            background-color: #ed8a35;
            border-radius: 30px;
        }

        &-renwu {
            border-radius: 10px;
            @media (max-width: 1000px) {
                margin-bottom: 20px;
                font-size: 12px!important;
                & img{
                    width: 100% !important;
                }
            }
        }

        &-bottom-box {
            @media (max-width: 1000px) {
                height: 250px;
                overflow-y: scroll;
            }
        }
    }
</style>
