import Vue from 'vue'
import Vuex from 'vuex'
import { getToken,removeToken } from '@/utils/auth.js'
import $api from '@/api/login'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_info:{},
    web_info:{},
    steam_handle:'',
    login_top:false,
    login_is_show:true,
    is_show_nav_pc:false,
    is_show_nav_phone:false,
    mobile:undefined,
    game_list_pk:[],
    reister:'',
    login_top_is_xie_yi:false,

  },
  mutations: {

    GAME_LIST_PK(state,data){
      state.game_list_pk.push(data)
    },
    GAME_LIST_PK_DEL(state,data){
      let index = state.game_list_pk.findIndex(item =>{
        return item.id == data.id
      })
      state.game_list_pk.splice(index,1)
    },
    USER_INFO(state,data){

      state.user_info = data
      state.mobile = data.mobile
    },
    WEB_INFO(state,data){
      state.web_info = data
    },

    LOGIN_TOP(state,data){
      state.login_top = data
    },
    LOGIN_IS_SHOW(state,data){
      state.login_is_show = data
    },
    ISSHOWNAVPC(state,data){
      state.is_show_nav_pc = data
    },
    ISSHOWNAVPHONE(state,data){
      state.is_show_nav_phone = data
    },
    REGISTER(state,data){
      state.reister = data
    },
    LOGIN_TOP_IS_XIE_YI(state,data){
      state.login_top_is_xie_yi = data
    },
  },
  actions: {
    gameListPK(context,payload){
      context.commit('GAME_LIST_PK',payload)
    },
    removeInfo(context){
      context.commit('USER_INFO',{})
      removeToken()
    },
    async userInfo (context) {
      let Token = getToken()
      if(Token){
        let data = await $api.getUsetInof()
        if(data.code != 200)return
        if(data === undefined) {
          context.commit('USER_INFO',{})
          removeToken()
        }else {
          context.commit('USER_INFO',data.data)
        }
      }else {
        context.commit('USER_INFO',{})
        removeToken()
      }
      let {data:webInfo} = await $api.getWebInfo()
      context.commit('WEB_INFO',webInfo)
    }
  },
  modules: {
  }
})
