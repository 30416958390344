

import {request} from "../index";

function getarticle(){
    return request({
        url: `/article`,
        method:'get'
    })
}
function getarticledetail(id){
    return request({
        url: `/article/detail?id=${id}`,
        method:'get'
    })
}

export default {
    getarticle,
    getarticledetail
}
