<template>
  <!-- 移动端上方logo以及导航与登录注册 -->
  <div class="header p-0 m-0">
    <div class="header_PHONE" >
      <!-- 手机下按钮 -->
      <div class=" position-fixed top-0 d-flex justify-content-between align-items-center px-1 w-100 phonebnt"
        style="height: 56px;background-color: #05377b;">
        <!-- <div @click="mobile_click_nav = !mobile_click_nav">
                    <i class="el-icon-s-unfold h2"></i>
                </div> -->
        <div class="">
          <router-link to="/">
            <img :src="require('@/assets/img2/sy-slices/导航栏_logo.png')" height="80px" alt="">
          </router-link>
        </div>

        <div v-if="login_is_show" @click="login" class="login-bnts">
          <div class="family" style="font-size:16px;line-height:16px;">登录/注册</div>
        </div>
        <div v-if="!login_is_show" class="ml-md-auto d-flex">
          <div class=" position-relative d-none"  @click="$router.push({ path: '/user/user' })">
            <img width="35px" height="35px" class="rounded-circle" :src="user_info.avatar" alt="">
            <span class="header-user-name-vip position-absolute"><img
                :src="`${require(`@/assets/img/nav/${user_info.vip_level}.png`)}`" alt=""></span>
          </div>

          <div @click="$router.push({ path: '/user/recharge' })">
            <div class="text-overflow-1 cursor-pointer
                         d-flex align-items-center text-white login-bnts"
              style="font-size: 14px;height: 50px;width: 100px;">
              <div style="width: 90px;">
                <div class="d-flex " style="font-size: 12px ;width: 100%;">
                  <money color="#FFF" />
                  {{ user_info.bean || '0.00' }}
                </div>
                <div class="d-flex " style="font-size: 12px;width: 100%;">
                  <money :integral="false" color="#FFF" />
                  {{ user_info.integral || '0.00' }}
                </div>
              </div>
            </div>
          </div>

          <div class="d-none">
            <i class="el-icon-s-unfold h2" @click="mobile_click_nav = !mobile_click_nav"></i>
          </div>

        </div>
      </div>

    </div>

    <div class="header-phone-left position-relative align-items-center">
      <div v-show="is_show_nav_phone ? 'd-block' : ''" class="position-absolute header-phone-left-nav">
        <router-link v-for="(item, index) in routers" :key="index" :to="'/' + item.path" v-show="item.meta.isShow"
          class="" :active-class="'header-conter-active header-phone-left-active'">
          <div class="pl-3 py-3" style="">
            <div class="">
              {{ item.meta.title }}
            </div>
          </div>
        </router-link>
      </div>
      <div @click="offNav" v-if="is_show_nav_phone ? 'd-block' : ''" class="header-phone-left-nav-bg"></div>
    </div>

    <!-- 点击之后显示的导肮 - 只关注手机端 -->
    <div class="position-fixed app-phone d-block"
      style="z-index: 99;">
      <div class="app-phone-nav">
        <div class="d-flex app-phone-nav-item flex-column align-items-center" @click="$router.push('/')"
          :class="`${$route.name === 'SCGOindex' ? 'nav-active' : ''}`">
          <img v-if="$route.name === 'SCGOindex'" style="width:35px" src="@/assets/img2/U8_slices/切图 149 2.png" alt="">
          <img v-else style="width:35px" src="@/assets/img2/U8_slices/切图 149.png" alt="">
          <!-- <span style="font-size: 12px">首页</span> -->
        </div>
        <div class="d-flex app-phone-nav-item flex-column align-items-center" @click="$router.push({ name: 'rollHome' })"
          :class="`${$route.name === 'rollHome' ? 'nav-active' : ''}`">
          <img v-if="$route.name === 'rollHome'" style="width:35px" src="@/assets/img2/U8_slices/切图 151 2.png" alt="">
          <img v-else style="width:35px" src="@/assets/img2/U8_slices/切图 151.png" alt="">
          <!-- <span style="font-size: 12px">ROLL房</span> -->
        </div>
        <div class="d-flex app-phone-nav-item flex-column align-items-center"
          @click="$router.push({ name: 'mysteryBox' })" :class="`${$route.name === 'mysteryBox' ? 'nav-active' : ''}`">
          <img v-if="$route.name === 'mysteryBox'" style="width:35px" src="@/assets/img2/U8_slices/切图 150 2.png" alt="">
          <img v-else style="width:35px" src="@/assets/img2/U8_slices/切图 150.png" alt="">
          <!-- <span  style="font-size: 12px">
                      竞技场
                    </span> -->
        </div>
        <div class="d-flex app-phone-nav-item flex-column align-items-center" @click="$router.push({ name: 'shopping' })"
          :class="`${$route.name === 'shopping' ? 'nav-active' : ''}`">
          <img v-if="$route.name === 'shopping'" style="width:35px" src="@/assets/img2/U8_slices/切图 152 2.png" alt="">
          <img v-else style="width:35px" src="@/assets/img2/U8_slices/切图 152.png" alt="">
          <!-- <span style="font-size: 12px">积分商城</span> -->
        </div>
        <div class="d-flex app-phone-nav-item flex-column align-items-center" @click="userInfo"
          :class="`${$route.name === 'info' ? 'nav-active' : ''}`">
          <img v-if="$route.name === 'info'" style="width:35px" src="@/assets/img2/U8_slices/切图 153 2.png" alt="">
          <img v-else style="width:35px" src="@/assets/img2/U8_slices/切图 153.png" alt="">
          <!-- <span style="font-size: 12px">我的</span> -->
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { getToken } from '@/utils/auth'
import $api from '@/api/help/index'
import { mapState } from 'vuex'

export default {
  name: 'headerTop',
  data() {
    return {
      routers: [],
      routersUser: [],
      isShowNavActive: false,
      isShowNavPc: false,
      mobile_click_nav: false,
      mobile_click_nav_index: 0,
      off_laba: true,
      // 文章列表
      hrlpList: [],
      help: false,


      // 登陆之前点击显示
      login_is_show_pup: this.login_top_is_xie_yi,
      checked01: false,
      checked02: false,
      checked03: false,
      checked04: false,

    }
  },
  mounted() {
    this.getarticle()
    this.routers = this.$router.options.routes[0].children
    let rouer = this.$router.options.routes.find(item => {
      return item.name == "user"
    })
    this.routersUser = rouer.children
    if (getToken()) {
      setTimeout(() => {
        this.$store.commit("LOGIN_IS_SHOW", false)
      }, 500)
    }
  },
  computed: {
    ...mapState(['web_info', 'user_info', 'steam_handle', 'login_top', 'login_is_show', 'is_show_nav_pc', 'is_show_nav_phone', 'login_top_is_xie_yi']),
  },
  methods: {
    userInfo() {
      if (getToken()) {
        this.$router.push({ name: 'info' })
      } else {
        this.$store.commit("LOGIN_TOP", true)
      }
    },
    login_is_show_pup_off() {
      this.login_is_show_pup = false
      this.$store.commit("LOGIN_TOP_IS_XIE_YI", false)
    },
    // 获取文章列表
    async getarticle() {
      let { data } = await $api.getarticle()
      this.hrlpList = data
    },
    // 注册
    register() {
      this.$store.commit("LOGIN_TOP", true)
      this.$store.commit("REGISTER", 'register')
    },
    // steam 提示登录
    steamLogin() {
      this.$confirm('打开加速器，跳转更快捷呦！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(() => {
        // window.open("https://api.aaskins.cn/api/steam/login", '_blank')
        // window.open("https://api.9m17.chunblog.com/api/steam/login", '_blank')
      }).catch(() => {

      });
    },
    mobile_click_nav_url(item, index) {
      this.mobile_click_nav = false
      this.mobile_click_nav_index = index
      this.$router.push({ path: '/' + item.path })
    },
    laba_off_on() {
      this.off_laba = !this.off_laba
      if (!this.off_laba) {
        document.querySelectorAll("video, audio").forEach(elem => {
          elem.muted = true;
          elem.pause();
        });
      } else {
        document.querySelectorAll("video, audio").forEach(elem => {
          elem.muted = false;
          // elem.play();
        });
      }

    },
    userNav() {
      this.isShowNavPc = !this.isShowNavPc
      this.$store.commit('ISSHOWNAVPC', this.isShowNavPc)

    },
    login() {
      // this.$baidu.baiduApi(4) // 百度
      // if(!this.checked01||!this.checked02||!this.checked03||!this.checked04){
      //   this.$notify({
      //     title: '警告',
      //     message: '请同意以上条款',
      //     type: 'warning',
      //     position: 'top-left'
      //   });
      //   return
      // }
      this.login_is_show_pup = false
      if (!getToken()) {
        this.$store.commit("LOGIN_TOP", true)
      } else {
        this.$store.commit("LOGIN_IS_SHOW", false)
      }
    },
    loginOut() {
      this.$confirm('您是否退出登录！', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'success',
        customClass: 'pkClass'
      }).then(() => {
        this.$store.commit("LOGIN_IS_SHOW", true)
        this.$store.dispatch('removeInfo')
        this.$router.push('/')
      }).catch(() => {
        console.log(12)
      })

    },
    // 手机端点击显示导航
    isShowNav() {
      this.isShowNavActive = !this.isShowNavActive
      // this.$store.commit("ISSHOWNAVPC",false)
      this.$store.commit("ISSHOWNAVPHONE", true)
    },
    // 关闭导航手机端
    offNav() {
      this.isShowNavActive = false
      // this.$store.commit("ISSHOWNAVPC",false)
      this.$store.commit("ISSHOWNAVPHONE", false)

    }
  }
}
</script>
<style scoped lang="scss">
.phonebnt{
  @media (min-width:450px) {
    max-width: 450px !important;
  }
}
.app-phone {
  bottom: 0;
  left: 0;
  right: 0;
  // box-shadow: 0 0 36px 16px #00000068 inset;
  /*background-image: url('../assets/img/phone-nav/navbg-bottom.png');*/
  background-size: 100% 100%;
  background-color: #c0bfbfc0;
  @media (min-width: 720px) {
    width: 450px;
    margin: auto;
  }
  &-nav {
    display: flex;
    align-items: center;

    &-item {
      display: flex;
      align-items: center;
      width: 20%;
      padding: 10px 0;
    }

    .nav-active {
      // background-color: #15151c;
      box-shadow: 0 0 20px 2px #05367b57 inset;
    }
  }
}
.app-phone:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.297);
    z-index: -1;
}


.border-t-b {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

.phone_nav_list>.phone_nav {
  background-size: 100% 100%;
  background-image: url('../assets/img/nav/nav-item-active.png');
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    /*background-color: var(--main-yellow);*/
  }
}

.pc {
  width: 250px;
  overflow: auto;
  height: 100%;

  &::-webkit-scrollbar {
    display: none
  }
}

.shrink {
  width: 70px;

  &-title {
    position: absolute;
    z-index: -2;
    white-space: nowrap;
    text-align: center;
    width: 0;
    left: 100%;
    overflow: hidden;
    transition: all .5s;
  }

  &-con:hover {
    .shrink-title {
      width: 150px;

    }
  }
}


.top {
  height: 56px;
}

.header {
  height: 100vh;
  background-color: rgba(0, 0, 0, .3);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);

  &-phone {
    &-left {
      height: 100%;

      &-nav {
        top: 100%;
        position: relative;
        width: 300%;
        height: 100vh;
        background: var(--main-bg);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
        z-index: 2;

        &-bg {
          position: absolute;
          width: 100vw;
          height: calc(100vh - 80px);
          background: #000000;
          opacity: .8;
          z-index: -2;
          top: 80px;
          left: 0;
        }

      }

      & span {
        display: block;
        width: 40px;
        height: 3px;
        border-radius: 2px;
        background: red;
        margin: 6px 20px;
        transition: all .5s;
      }

      &:hover {

        & span:nth-child(1) {
          transform: rotate(180deg);
        }

        & span:nth-child(3) {
          transform: rotate(-180deg);
        }

      }

      &-active>div {
        border-left: 4px solid;
        background-image: var(--main-yellow-gradual-x);
      }
    }
  }

  &-left {
    width: 100%;
    height: 160px;

    & img {
      height: 100%;
    }
  }

  &-conter {
    width: 100%;
    height: 100%;
    margin-left: 40px;


    &-item {
      width: 11%;
      height: 100%;
      background-size: 100% 100%;
      filter: grayscale(1);
      font-size: 1.3rem;
      transition: all .3s;


      &-text {
        margin-top: 5px;
        font-size: .9rem;

      }

      &:hover {
        color: var(--main-yellow);
        filter: grayscale(0);
        background-image: url('../assets/img/nav/nav-item-active.png');
      }

    }

    &-active {
      color: var(--main-yellow);
      filter: grayscale(0);

      &::before {
        content: '';
        width: 2px;
        height: 50px;
        background-color: var(--main-purple);
        ;
        position: absolute;
        left: 0;
      }

      &>div {
        background-size: 100% 100%;
        background-image: url('../assets/img/nav/nav-item-active.png');
      }
    }

  }

  &-login {
    /*background-image: url('../assets/img/nav/login.png');*/
    background-size: 100% 100%;
    font-size: 14px;
    width: 140px;
    background-color: #e94d8b;
    border: 1px solid #e94d8b;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;

  }

  &-user {
    width: 370px;

    &-phone {

      & .active2 {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        top: 60px;
      }

      &-title {
        /*background: rgba(0, 0, 0, 0.5);*/
        /*border: 1px solid #000000;*/
        border-radius: 5px;
        padding: 5px 5px;
        line-height: 22px;
      }
    }

    & .active {
      display: block !important;
    }

    &-nav {
      display: none;
      width: 250px;
      position: absolute;
      top: 30px;
      left: 0;
      text-align: center;
      padding: 20px 0;
      z-index: -1;
      background: var(--main-bg);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);

      &-active {
        color: var(--main-yellow)
      }

      &-hove {
        transition: all .5s;
      }

      &-hove:hover {
        color: var(--main-yellow)
      }
    }

    &-img {
      right: 10px;
    }

    &-name {
      width: 230px;
      /*padding: 0 10px;*/
      overflow-y: hidden;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;

      &-vip {
        margin-left: 5px;
        width: 40px;
        height: 18px;
        bottom: 0px;
        right: -15px;

        & img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &-money {
      width: 250px;
      border: 3px solid #000000;
      border-left: 0;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }

    &-name,
    &-money {
      font-size: 14px;
      line-height: 30px;
    }
  }
}


.bonanza {
  position: relative;

  margin-bottom: 10px;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background-size: 100% 100%;
  }
}
.login-bnts{
  border: 3px solid #009FFF;
  border-radius: 26px;
  height: 40px;
  padding: 10px;
}
</style>
