import router from './router'
import store from './store'
import { getToken,} from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'
router.beforeEach(async(to, from, next) => {
    document.title = '52skins.link' + getPageTitle(to.meta.title)
    next()
})

router.afterEach((to) => {

    let token = getToken()
    store.dispatch('userInfo')
    if(token == undefined){
        store.commit('LOGIN_IS_SHOW',true)
    }
    if(to.meta.login && !token ){
        router.push('/')
        store.dispatch('userInfo')
        store.commit('LOGIN_TOP',true)
    }
    if(to.name != 'mysteryBox'){
        window.scrollTo(0,0);
    }
})


