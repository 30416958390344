<template>
<!-- 网页下方帮助导航以及入网备案 -->
    <div>
        <div class="footer bg-bg-2 text-center">

            <div class="d-md-block">
                        <img width="250px" :src="require('@/assets/img/logo.png')" alt="">
             </div>
            <div class="d-flex pt-2">
                <div class="col-md-6 col-12 d-flex text-left m-auto">
                    <div class="d-flex justify-content-center w-100">
                        <div class="d-flex justify-content-between mt-2 w-100">
                            <a target="_blank" href="/active/help/2" class=" mb-2">用户协议</a>
                            <a target="_blank" href="/active/help/3" class=" mb-2">隐私条款</a>
                            <a target="_blank" href="/active/help/4" class=" mb-2">协议与条款</a>
                            <a target="_blank" href="/help" class="">帮助中心</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-3 d-md-flex d-block justify-content-around align-items-center mt-3 pb-5">
              <div class=" w-100">
                <div class="f_mean mt-1" >
                  <a target="_blank" href="https://beian.miit.gov.cn/">{{$store.state.web_info.website_icp || `浙ICP备2022032654号`}}</a>
                </div>
                <div class=" mt-1" >
                  <img src="@/assets/img/GA.png" class="mr-1" alt="">
                  <a target="_blank" href="https://beian.miit.gov.cn/">浙公网安备 33012702000474号</a>
                </div>

              </div>
            </div>
        </div> 
        <div>

        </div><template>
    <div>

    </div>
</template>
    </div>

</template>
<script>
    export default {
        name: "footerBotton",
        data() {
            return {
                data: true,
                webInfo: {},
            };
        },
        mounted() {
            this.webInfo = this.$store.state.web_info
        },
        methods: {

        },
    };
</script>

<style scoped lang="scss">
    .footer {
        width: 100%;
        margin-left: auto;
        font-size: 12px;
        margin-top: 80px;
        position: relative;
        z-index: 0;
        border-top: 2px solid var(--main-blue);
        background-color: var(--f-main);

        &-statistics {
            margin-bottom: 35px;
            padding: 40px 0;
            background-color: #191926;
            --gradient-color: 255,255,255;
            --gradient-opacity: 0.2;
            border-style: solid;
            border-width: 1px;
            border-image-slice: 1;
            border-image-source: linear-gradient(to right, rgba(var(--gradient-color), 0), rgba(var(--gradient-color), 0) 28%, rgba(var(--gradient-color), var(--gradient-opacity)) 50%, rgba(var(--gradient-color), 0) 74%, rgba(var(--gradient-color), 0) 0%);
            justify-content: space-around;

            &-item {
                text-shadow: 0 0 60px #e94d8b;
            }

            & svg {
                font-size: 40px;
            }

            @media (max-width:1200px) {
                width: 100%;
                margin: 15px auto;
            }
        }

        &-title {
            width: 600px;
            margin: 0 auto;
            padding: 10px 0;

            @media (max-width:1200px) {
                width: 90%;
                margin: 15px auto;
            }
        }
    }
</style>
