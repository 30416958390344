<template>
  <div class="d-flex big">
    <div class="content">
      <!-- 轮播图 -->

      <!--  -->
      <!--  -->
      <div class="Phone">
        <div class="content-roll d-flex justify-content-between">
          <img src="../../assets/img2/hb.png" alt="" class="swiper-img" />

          <swiper class="swiper" :options="options_phone">
            <swiper-slide
              class="position-relative"
              v-for="(item, index) in banners" :key="index">
              <div class="swiper-slide swiper-roll">
                <a :href="item.href">
                  <img
                    class="swiper-slide-img"
                    :src="item.image"
                    style="width: 100%; height: 100%"
                  />
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- 导航 -->
      <div class="index-phone mt-3">
        <div class="d-flex justify-content-around flex-wrap">
          <div @click="$router.push({ name: 'center' })">
            <template>
              <div class="index-phone-item titleimgs titleimgsone">
                <!--<img style="width: 60px" class="m-auto" src="@/assets/img2/sy-slices/控件_福利.png" alt="#" />-->
                <!--<div class="mt-1" style="font-size: 12px">积分商城</div>-->
              </div>
            </template>
          </div>
          <div @click="$router.push({ name: 'promote' })">
            <template>
              <div class="index-phone-item titleimgs titleimgstwo">
                <!-- <img style="width: 60px" class="m-auto" src="@/assets/img2/sy-slices/控件_竞技.png" alt="#" /> -->
                <!-- <div class="mt-1" style="font-size: 12px">合伙人</div> -->
              </div>
            </template>
          </div>
          <div @click="$router.push({ name: 'recharge' })">
            <template>
              <div class="index-phone-item titleimgs titleimgsthree">
                <!-- <img style="width: 60px" class="m-auto" src="@/assets/img2/sy-slices/控件_充值.png" alt="#" /> -->
                <!-- <div class="mt-1" style="font-size: 12px">充值</div> -->
              </div>
            </template>
          </div>
          <div
            @click="$router.push({ name: 'welfare' })"
            style="pointer-events: none"
          >
            <template>
              <div class="index-phone-item titleimgs titleimgsfour">
                <!-- <img style="width: 60px" class="m-auto" src="@/assets/img2/sy-slices/控件_活动.png" alt="#" /> -->
                <!-- <div class="mt-1" style="font-size: 12px">合伙人</div> -->
              </div>
            </template>
          </div>
          <div @click="keFu" style="pointer-events: none">
            <template>
              <div class="index-phone-item titleimgs titleimgsfive">
                <!-- <img style="width: 60px" class="m-auto" src="@/assets/img2/sy-slices/控件_合炉.png" alt="#" /> -->
                <!-- <div class="mt-1" style="font-size: 12px">客服</div> -->
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="zs-img" :style="`background-image:url('');`">
        <img src="../../assets/img2/syzs.png" alt="" srcset="" />
      </div>
      <!-- 宝箱 -->
      <div class="content-box">
        <div v-for="(t, i) in boxList" :key="i">
          <div class="content-box-title">
            <div
              class="text-center content-box-title-top"
              :style="`background-image:url(${t.src});`"
            ></div>
          </div>
          <div class="content-box-list d-flex flex-wrap justify-content-center">
            <div
              v-for="(item, index) in t.box"
              :key="index"
              class="content-box-list-item"
            >
              <router-link :to="'/active/' + item.id" class=" ">
                <div
                  @click="openBox"
                  class="text-center d-flex flex-column align-items-center m-auto py-2 overflow-hidden content-box-list-item-f py"
                  style="width: 95%"
                >
                  <div
                    class="col-md-8 col-lg-10 col-12 px-lg-3 px-1 content-box-list-item-img position-relative d-flex justify-content-center align-items-center"
                  >
                    <img
                      class="position-relative bottombox"
                      :src="item.cover"
                      alt="底部箱子"
                    />
                    <img
                      class="position-absolute z-index-2 gunboxs"
                      :src="item.weapon_cover"
                      alt="抢"
                    />
                  </div>
                  <div
                    class="col-12 position-relative"
                    style="width: 90%; margin: 0 auto"
                  >
                    <div
                      class="col-lg-6 col-12 mx-auto justify-content-center title_qian d-flex align-items-center family"
                      style="padding: 20px 0"
                    >
                      {{ item.name }}
                    </div>

                    <div style="font-size: 14px"><money /> {{ item.bean }}</div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="indexPup d-flex justify-content-center align-items-center"
      v-if="false"
    >
      <div class="position-relative">
        <img
          src="@/assets/img/index/topPup.png"
          alt=""
          @click="reminderClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $api from "@/api/CSGO/index";
import { mapState } from "vuex";
import { getToken } from "@/utils/auth";

import {
  Swiper,
  SwiperSlide,
} from "vue-awesome-swiper/dist/vue-awesome-swiper.esm";
import "swiper/css/swiper.css";
import { getWebInfo } from "@/utils/auth";

export default {
  name: "index",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      routers: [],
      reminder: true,
      boxList: [],
      sideBoxList: [],
      ROLLList: [],
      options: {
        watchOverflow: true,
        slidesPerView: 2,
        loop: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      options_phone: {
        watchOverflow: true,
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
      },
      webInfo: {},
      openBoxState: {
        openBox: 0,
        userSum: 0,
        onLine: 0,
        pkSum: 0,
      },
      time: null,
      banners: [],
    };
  },
  created() {},
  computed: {
    ...mapState(["web_info"]),
  },
  mounted() {
    this.routers = this.$router.options.routes[0].children;
    this.init();
    let codeRef = this.$route.query.ref_code;
    if (codeRef != undefined) {
      window.sessionStorage.setItem("codeRef", codeRef);
    }
    setTimeout(() => {
      this.swiper = new Swiper(".swiper");
    }, 100);
    if (getToken()) {
      this.reminder = false;
    } else {
      this.reminder = true;
    }
  },
  beforeDestroy() {
    clearInterval(this.time);
    this.time = null;
  },
  methods: {
    get_banners() {
      $api.get_banners().then((res) => {
        this.banners = res.data;
      });
    },
    // 客服
    keFu() {
      window.open(
        "https://tb.53kf.com/code/client/5d2e97394292cbd902a6fa892620374d4/1",
        "_blank"
      );
    },
    onSwiper() {},
    onSlideChange() {},
    async init() {
      this.getBoxList();
      this.get_banners();
      this.getROllListthree();
      this.get_box_hot();
      this.sumState();
      this.webInfo = JSON.parse(getWebInfo());
    },
    sumState() {
      this.openBoxState.openBox = 1500 + Math.floor(Math.random() * 100);
      this.openBoxState.userSum = 1050 + Math.floor(Math.random() * 100);
      this.openBoxState.onLine = 1000 + Math.floor(Math.random() * 100);
      this.openBoxState.pkSum = 1000 + Math.floor(Math.random() * 100);
      let i = 0;
      this.time = setInterval(() => {
        if (Math.floor(Math.random() * 10) > 3) {
          setTimeout(() => {
            this.openBoxState.openBox += Math.floor(Math.random() * 50);
            this.openBoxState.onLine += Math.floor(Math.random() * 50);
          }, 500);
          this.openBoxState.userSum += Math.floor(Math.random() * 100);
          this.openBoxState.pkSum += Math.floor(Math.random() * 100);
        } else {
          setTimeout(() => {
            this.openBoxState.openBox -= Math.floor(Math.random() * 50);
            this.openBoxState.onLine -= Math.floor(Math.random() * 50);
          }, 500);
          this.openBoxState.userSum -= Math.floor(Math.random() * 50);

          this.openBoxState.pkSum -= Math.floor(Math.random() * 50);
        }
        i++;
        if (i === 50) {
          clearInterval(this.time);
          this.sumState();
        }
      }, 1000);
    },
    openBox() {
      document.getElementById("mysound").play();
    },
    mouseOverOpenBox() {
      document.getElementById("mouseOverBox").play();
    },
    mouseLeaveOpenBox() {
      document.getElementById("mouseOverBox").load();
    },
    reminderClick() {
      if (getToken()) {
        this.reminder = false;
        return;
      }
      this.reminder = false;
      this.$store.commit("LOGIN_TOP", true);
    },
    sideBox(item) {
      this.$router.push("/active/" + item.id);
    },
    async getBoxList() {
      try {
        let { data, code } = await $api.getBoxList();
        if (code == 500) return;
        this.boxList = data;
        this.sideBoxList = data[0].box;
      } catch (e) {
        console.log(e);
      }
    },
    async get_box_hot() {
      try {
        let { data, code } = await $api.get_box_hot();
        if (code == 500) return;
        this.sideBoxList = data;
      } catch (e) {
        console.log(e);
      }
    },
    async getROllListthree() {
      try {
        let {
          data: { data },
          code,
        } = await $api.getROllListthree();
        if (code == 500) return;
        this.ROLLList = data.slice(0, 3);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.swiper-slide-img {
  @media (max-width: 1000px) {
    transform-origin: top;
    /*transform: scaleX(1.5);*/
  }
}

.zs-img {
  margin-top: 10px;
  height: 60px;
  & img {
    width: 100%;
    @media (min-width: 450px) {
      width: 450px !important;
    }
  }
}

.indexPup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 1000px) {
    top: 0px;

    & img {
      width: 100%;
    }
  }
}

// 新写的手机端
.index-phone {
  margin-top: 1.5rem !important;
  background-image: url("../../assets/img2/sy-slices/切图 27.png");
  background-size: 100% 100%;
  padding: 20px 10px;
  border-radius: 12px;
  @media (min-width: 450px) {
    margin-top: 0.4rem !important;
  }
  &-item {
    width: 100%;
    margin-bottom: 3px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
  }
}

.price_bottom_bg {
  display: inline-block;
  padding: 20px 20px;
  font-weight: 700;
  font-size: 14px;
  background-size: 100% 100%;
  // background-color: #f3a45d;
  border-radius: 3px;
  margin-top: 8px;
  font-family: Roboto;
}

.sum-stast {
  font-size: 30px;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
}

.top-sum {
  font-size: 70px;

  @media (max-width: 1000px) {
    font-size: 24px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 90%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 40%;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 40%;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
}
.content-roll {
  position: relative;
  margin-top: 40px;
  @media (min-width: 450px) {
    margin-top: 70px;
  }
}
.swiper-img {
  position: absolute;
  z-index: 3;
  display: block;
  width: 100%;
  height: auto;
  @media (min-width: 720px) {
    width: 440px;
  }
}

.swiper {
  z-index: 1;
  top: 26px;
  margin-bottom: 30px;

  @media (max-width: 1000px) {
    // height: 4rem;
  }

  &-roll {
    height: 100%;
    transition: all 0.5s;

    &:hover {
      background-size: 110% 110%;
    }

    &-user {
      width: 86px;
      height: 86px;
      left: -40px;
      overflow: hidden;
      background-color: #090827;
      border-radius: 14px;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.3);

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.5;
        background: linear-gradient(
          144deg,
          rgba(57, 85, 232, 0) -11%,
          rgba(57, 85, 232, 0.6) 111%
        );
      }
    }
  }

  &-cover {
    width: 130px;
    left: calc(50% - 60px);
    top: calc(50% - 40px);
    height: 100px;
  }
}

.side-box {
  width: 200px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.content {
  width: 100%;
  margin: 0 auto;

  &-roll {
    width: 100%;

    @media (max-width: 1366px) {
      &-item:nth-child(3) {
        display: none !important;
      }
    }

    &-item {
      width: 31%;
      transition: all 0.5s;
      overflow: hidden;

      @media (max-width: 1600px) {
        width: 32%;
      }

      @media (max-width: 1366px) {
        width: 48%;
      }

      @media (max-width: 760px) {
        width: 48%;
      }

      &:hover {
        transform: translateY(-20px);
      }

      &-bg {
        left: 20%;
        z-index: 0;
      }
    }
  }

  &-activity {
    margin-left: 20px;
  }

  &-box {
    &-title {
      &-top {
        height: 62px;
        // border-top: 1px solid var(--main-blue);
        // border-bottom: 1px solid var(--main-blue);
        background-size: 100% 100%;
        background-position: top;
        background-repeat: no-repeat;

        // background-size: 100% 100%;
      }

      &-name {
        font-size: 28px !important;
        border-top: 1px solid rgba(var(--main-blue), 1);
        border-bottom: 1px solid rgba(var(--main-blue), 1);
        // background-color: #0d101a;
        height: 70px;
        line-height: 70px;

        @media (max-width: 1000px) {
          height: 40px;
          line-height: 40px;
        }
      }
    }

    &-list {
      width: 100%;
      display: flex;

      &-item {
        width: 50%;
        &-img img {
          transition: all 0.5s;
        }

        &-open {
          width: 100px;
          margin: 0 auto;
          opacity: 0;
          transform: scale(5, 5);
          transition: all 0.5s;

          & > div {
            border-radius: 4px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
          }
        }

        .f_title {
          transition: all 0.5s;
          font-weight: 600;
        }

        &-f {
          background-size: 150% 150%;
          background-position: center;
          transition: all 0.5s;
        }

        &:hover &-f {
          background-size: 100% 100%;
        }

        &:hover &-img img:nth-child(2) {
          transform: translateY(-20px);
          // transform: scale(1.2)
        }

        &-money {
          color: var(--main-yellow);
          font-size: 18px;
        }

        &:hover &-open {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes boxBg {
  to {
    transform: scale(0.8);
  }

  from {
    transform: scale(1.15);
  }
}

@keyframes boxBox {
  to {
    transform: rotate(3deg);
  }

  from {
    transform: rotate(-10deg);
  }
}
.titleimgs {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background-image: url("../../assets/img2/sy-slices/dhjlt.png");
  background-size: 671%;
}
.titleimgsone {
  background-position: -6px -6px;
}
.titleimgstwo {
  background-position: -78px -6px;
}
.titleimgsthree {
  background-position: -151px -6px;
}
.titleimgsfour {
  background-position: -223px -6px;
}
.titleimgsfive {
  background-position: -296px -6px;
}
.bottombox {
  width: 130%;
  @media (min-width: 450px) {
    width: 260px;
  }
}
.gunboxs {
  width: 90%;
  height: 100px;
  @media (min-width: 450px) {
    width: 60%;
  }
}
.py{
  @media (min-width: 450px) {
    padding: .2rem 0 !important; 
  }
}
</style>
