import {request} from "../index";

function getRebBackList(){
    return request({
        url: `/red`,
        method:'get'
    })
}
function getOpenRebBack(data){
    return request({
        url: `/red/open`,
        method:'post',
        data
    })
}

export default {
    getRebBackList,
    getOpenRebBack
}
