import {request} from "../index";

function getTask_sign_up(data){
  return request({
    url: `/task/sign_up`,
    method:'post',
    data
  })
}



export default {
  getTask_sign_up
}
