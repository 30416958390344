import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/layout.vue'
// 测试
import ceshi from '@/views/Home.vue'


// 页面
import CSGO from '@/views/CSGO/index'

const rollHome = () => import('@/views/rollHome/index')
const compound = () => import('@/views/compound/index')
const shopping = () => import('@/views/shopping/index')
const lucky = () => import('@/views/lucky/index')
const mysteryBox = () => import('@/views/mysteryBox/index')
const creationpkHome = () => import('@/views/mysteryBox/creationHome')
const pkrule = () => import('@/views/mysteryBox/pkrule')
const pkhistory = () => import('@/views/mysteryBox/pkhistory')
const help = () => import('@/views/help/index')
const memberVip = () => import('@/views/memberVip/index')
const outOfTheFree = () => import('@/views/outOfTheFree/indexV2')
const wxpage = () => import('@/views/WXPage/index')

// import compound from '@/views/compound/index'
// import shopping from '@/views/shopping/index'
// import lucky from '@/views/lucky/index'
// import mysteryBox from '@/views/mysteryBox/index'
// import creationpkHome from '@/views/mysteryBox/creationHome'
// import pkrule from '@/views/mysteryBox/pkrule'
// import pkhistory from '@/views/mysteryBox/pkhistory'
// import rollHome from '@/views/rollHome/index'
// import help from '@/views/help/index'
// import memberVip from '@/views/memberVip/index'
// import outOfTheFree from '@/views/outOfTheFree/indexV2'
// import wxpage from '@/views/WXPage/index'

// 详情
const CSGOActive = () => import('@/views/CSGO/active')
const rollHomeActive = () => import('@/views/rollHome/active')
const helpActive = () => import('@/views/help/active')
const luckyHistory = () => import('@/views/lucky/history')
const mysteryBoxActive = () => import('@/views/mysteryBox/active')
const outOfTheFreeActive = () => import('@/views/outOfTheFree/active')
const luckyActive = () => import('@/views/lucky/active')

// import CSGOActive from '@/views/CSGO/active'
// import rollHomeActive from '@/views/rollHome/active'
// import helpActive from '@/views/help/active'
// import luckyHistory from '@/views/lucky/history'
// import mysteryBoxActive from '@/views/mysteryBox/active'
// import outOfTheFreeActive from '@/views/outOfTheFree/active'
// import luckyActive from '@/views/lucky/active'

// 用户页面
const user = () => import('@/views/user/user')
const accountSetting = () => import('@/views/user/accountSetting')
const info = () => import('@/views/user/info')
const bill = () => import('@/views/user/bill')
const detail = () => import('@/views/user/detail')
const packsack = () => import('@/views/user/packsack')
const recharges = () => import('@/views/user/recharge')
const recharge = () => import('@/views/user/recharges')
const welfare = () => import('@/views/user/welfare')
const account = () => import('@/views/user/account')
const promote = () => import('@/views/user/promote')
const helpcenter = () => import('@/views/user/helpcenter')
const center = () => import('@/views/welfares/center')

// import user from '@/views/user/user'
// import accountSetting from '@/views/user/accountSetting'
// import info from '@/views/user/info'
// import bill from '@/views/user/bill'
// import detail from '@/views/user/detail'
// import packsack from '@/views/user/packsack'
// import recharges from '@/views/user/recharge'
// import recharge from '@/views/user/recharges'
// import welfare from '@/views/user/welfare'
// import account from '@/views/user/account'
// import promote from '@/views/user/promote'
// import helpcenter from '@/views/user/helpcenter'
//福利中心
// import center from '@/views/welfares/center'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', 
    component: layout, // 父组件 组件
    name:'index',
    redirect: '/', // 重定向
    children:[
      {
        path: '',
        name: 'SCGOindex',
        component: CSGO,
        beforeEach(to,from,next){
          console.log(to,from,next)
          next()
        },
        meta: { title: '暂定', icon: 'icon-shouye',phone:false, login:false,isShow:true,side:'箱子' },
      },
      {
        path: 'xsteryBox',
        name: 'mysteryBox',
        component: mysteryBox,
        meta: { title: '决战沙场', icon: 'icon-jiangbei',phone:false, login:false,isShow:true,side:'竞技场' },
      },
      {
        path: 'compound',
        name: 'compound',
        component: compound,
        meta: { title: '汰换升级', icon: 'icon-jiaohuan', phone: true, login: true, isShow: true,side:'升级' },
      },
      {
        path: 'rollHome',
        name: 'rollHome',
        component: rollHome,
        meta: { title: 'ROLL房', icon: 'icon-guanfang2', phone:false,login:false,isShow:true,side:'赠品' },
      },
      {
        path: 'shopping',
        name: 'shopping',
        component: shopping,
        meta: { title: '积分商城', icon: 'icon-chaozhijifen', phone:true,login:false,isShow:true ,side:'皮肤商城'},
      },

      {
        path: 'outOfTheFree',
        name: 'outOfTheFree',
        component: outOfTheFree,
        meta: { title: '福利宝箱', icon: 'icon-gouwuche',phone:true, login:false,isShow:true,side:'福利宝箱'  },
      },
      {
        path: 'user/welfare',
        name: 'welfare',
        component: welfare,
        meta: { title: '合伙人', icon: 'icon-yonghu',phone:true, login: true, side: '合伙人', isShow: true, },
      },

      {
        path: 'lucky',
        name: 'lucky',
        component: lucky,
        meta: { title: '追梦', icon: 'icon-siyecao', phone:true,login:true,isShow:true,side:'幸运拉货' },
      },
      {
        path: 'memberVip',
        name: 'memberVip',
        component: memberVip,
        meta: { title: '优质会员', icon: 'icon-VIP',phone:true, login: true, isShow: true, side: 'VIP福利' },
      },
  
      // {
      //   path: 'packsack',
      //   name: 'packsack',
      //   component: packsack,
      //   meta: { title: '我的背包', icon: 'icon-beibao1',phone:false, login:true,isShow:false,side:'个人中心' },
      //   },
      {
        path: 'help',
        name: 'help',
        component: help,
        meta: { title: '帮助中心', icon: 'icon-wenhao',phone:false, login:false,isShow:false },
      },


    ]
  },
  // 详情
  {
    path: '/active', 
    name:'active',
    component: layout, 
    children:[
      {
        path: ':id',
        name: 'CSGOActive',
        component: CSGOActive,
        meta: { title: '开箱详情页', icon: '',login:false,side:'最近掉落' },
      },
      {
        path: 'rollHome/:id',
        name: 'rollHomeActive',
        component: rollHomeActive,
        meta: { title: 'ROLL福利', icon: '', login:false,side:'ROLL福利' },
      },
   
  
      {
        path: 'luckyHistory/:id',
        name: 'history',
        component: luckyHistory,
        meta: { title: '记录', icon: '', login:false,side:'记录' },
      },
      {
        path: 'help/:id',
        name: 'helpActive',
        component: helpActive,
        meta: { title: '帮助中心', icon: '', login:false,side:'帮助中心' },
      },
    
      {
        path: 'mysterybox/:id',
        name: 'mysteryBox',
        component: mysteryBoxActive,
        meta: { title: '竞技场', icon: '', login:false,side:'竞技场'  },
      },
      {
        path: 'creation/pkHome',
        name: 'creationpkHome',
        component: creationpkHome,
        meta: { title: '创建对战', icon: '', login:false,side:'创建对战' },
      },
      {
        path: 'pkrule',
        name: 'pkrule',
        component: pkrule,
        meta: { title: '对战规则', icon: '', login:false,side:'对战规则' },
      },
      {
        path: 'pkhistory',
        name: 'pkhistory',
        component: pkhistory,
        meta: { title: '对战历史', icon: '', login:false,side:'对战历史' },
      },
      {
        path: 'outOfTheFree/:id',
        name: 'outOfTheFreeActive',
        component: outOfTheFreeActive,
        meta: { title: '充值福利', icon: '', login:false ,side:'充值福利'},
      },
      {
        path: 'lucky/:id',
        name: 'luckyActive',
        component: luckyActive,
        meta: { title: '幸运开箱', icon: '', login: false, side: '幸运开箱' },
      },

    ]
  },
  //福利中心
  {
    path: '/welfares',
    name: 'welfares',
    component: layout,
    children: [
      {
       path: 'center',
       name: 'center',
       component: center,
       meta: { title: '福利中心', icon: '',login:false,side:'福利中心',isShow:true }
      }
     ,
    ]
  },
  {
    path: '/user', 
    name:'user',
    component: layout,
    children:[
      {
        path: 'info',
        name: 'user',
        component: user,
        meta: { title: '个人中心', icon: '',login:false,side:'个人中心',isShow:true },
      },
      {
        path: 'accountSetting',
        name:'accountSetting',
        component: accountSetting,
        meta: { title: '个人中心', icon: '',login:false,side:'个人中心',isShow:true },
      },
      {
        path: 'user',
        name: 'info',
        component: info,
        meta: { title: '个人资料', icon: '',login:false,side:'个人资料',isShow:true },
      },
      {
        path: 'packsack',
        name: 'packsack',
        component: packsack,
        meta: { title: '我的背包', icon: 'icon-beibao', login:false,side:'我的背包',isShow:true },
      },
      {
        path: 'recharges',
        name: 'recharges',
        component: recharges,
        meta: { title: '充值记录', icon: '', login:true ,side:'充值记录',isShow:true},
      },
      {
        path: 'recharge',
        name: 'recharge',
        component: recharge,
        meta: { title: '充值页面', icon: '', login:true ,side:'充值页面',isShow:true},
      },
      {
        path: 'bill',
        name: 'bill',
        component: bill,
        meta: { title: '提货账单', icon: 'icon-a-ziyuan3', login:false,side:'提货账单',isShow:true },
      },
      {
        path: 'detail',
        name: 'detail',
        component: detail,
        meta: { title: '收支明细', icon: 'icon-a-ziyuan3', login:false,side:'收支明细',isShow:true },
      },
  
      {
        path: 'welfare',
        name: 'welfare',
        component: welfare,
        meta: { title: '会员推广', icon: 'icon-a-ziyuan3', login:false ,side:'会员推广',isShow:true},
      },
      {
        path: 'account',
        name: 'account',
        component: account,
        meta: { title: '账户记录', icon: '', login:false ,side:'账户记录',isShow:true},
      },
      {
        path: 'promote',
        name: 'promote',
        component: promote,
        meta: { title: '推广记录', icon: '', login:false ,side:'推广记录',isShow:true},
      },
      {
        path: 'helpcenter',
        name: 'helpcenter',
        component: helpcenter,
        meta: { title: '帮助中心', icon: '', login:false ,side:'帮助中心',isShow:true},
      },
    ]
  },
  {
    path: '/wxpage',
    name: 'wxpage',
    component: wxpage,
    meta: { title: '微信跳转', icon: 'icon-a-ziyuan3',login:false },
  },
  {
    path: '*',
    name: '*',
    redirect: '/', 
    meta: { title: 'CSGO', icon: 'icon-a-ziyuan3',login:false },
  },
  {
    path: '/',
    component: layout,
    name:'index',
    children:[
      {
        path: 'nologin',
        name: 'nologin',
        component: ceshi,
        meta: { title: 'nologin', icon: 'icon-a-ziyuan3',login:false },
      },
    ]
  },
 

]
const router = new VueRouter({
  mode: "history", //hash  history
  base: process.env.BASE_URL,
  routes
})

export default router
