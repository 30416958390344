<template>
  <!-- 侧边栏 页面滚动会隐藏 -->
  <div class="position-fixed silde" :style="`right:${silde}px`">
    <div class="position-absolute silde-off d-md-none d-none" @click="sildeOff">
      <span
        :class="`${silde === 0 ? 'el-icon-arrow-right' : 'el-icon-arrow-left'}`"
      ></span>
    </div>
    <!-- <div :class="isShowSidle ? 'd-none ' : ''" class="silde-box"> -->
      <!-- qq -->
      <!-- <div @click="QQQqun"
              class="position-relative cursor-pointer
              silde-serve silde-W d-flex  flex-column justify-content-center align-items-center"
              >
                <img width="50%" :src="require('@/assets/img/silde/help.png')" alt="">
                <div>
                    QQ
                </div>
                <div  class="position-absolute silde-weixin">
                    <img width="200px" :src="web_info.qq_group_qr_code" alt="">
                </div>
            </div> -->
      <!-- 客服 -->
      <!-- <div @click="keFu"
              class="position-relative cursor-pointer
              silde-serve silde-W d-md-flex d-none flex-column justify-content-center align-items-center">
                <img width="50%" :src="require('@/assets/img/silde/kefu.png')" alt="">
                <div style="font-size: 12px">
                    客服
                </div>
            </div> -->
      <!-- vip -->
      <!-- <div @click="memberVip"
              class="position-relative cursor-pointer silde-serve silde-W d-md-flex d-flex flex-column justify-content-center
              align-items-center" >
                <img width="50%" :src="require('@/assets/img/silde/vip.png')" alt="">
                <div>
                    vip
                </div>
            </div> -->
      <div
        @click="redPacket(1)"
        class="cursor-pointer silde-serve silde-W d-flex flex-column justify-content-center align-items-center"
      >
        <img
          width="100%"
          :src="require('@/assets/img/silde/hongbao.png')"
          alt=""
        />
        <div>红包</div>
      </div>
      <!-- 签到 -->
      <!-- <div>
                <Task-in :bottom-right="false">
                    <div class="cursor-pointer silde-serve silde-W d-flex flex-column
                      justify-content-center align-items-center">
                        <img width="50%" :src="require('@/assets/img/silde/QD.png')" alt="">
                    <div>
                        签到
                    </div>
                    </div>
                </Task-in>
            </div> -->
    <!-- </div> -->

    <div
      v-show="immediatelyShopping"
      class="position-fixed z-index-1"
      style="
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
      "
    >
      <div
        v-show="immediatelyShopping"
        class="position-fixed z-index-100 immediatelyShopping"
      >
        <div
          class="bg-size bg-repeat"
          :style="`background-image: url(${require('@/assets/img/vd/red_top.png')})`"
        >
          <div class="immediatelyShopping-box px-2 pt-3">
            <div class="d-flex"></div>
            <div class="text-center mt-1">
              加官群 {{ web_info.qq_group_number }}，抢更多福利！
            </div>
            <div
              class="d-flex justify-content-center px-3 immediatelyShopping-input"
            >
              <input
                placeholder="请输入红包口令"
                type="text"
                v-model="packet"
                class="d-block py-2"
                style="color: black"
              />
            </div>
            <div class="d-flex justify-content-center">
              <img
                @click="OpenRebBack(2)"
                width="60px"
                :src="require('@/assets/img/vd/kai.png')"
                alt=""
              />
            </div>
            <div class="mt-4 text-center">红包列表</div>
            <div class="immediatelyShopping-list mt-2">
              <div
                :class="item.status != 2 ? '' : 'filter-grayscale-1'"
                v-for="(item, index) in redList"
                :key="index"
                class="px-2 py-1 rounded mb-2"
                style="background-color: #ad252c"
              >
                <div class="d-flex mt-3">
                  <div
                    class="immediatelyShopping-item-img d-flex flex-column align-items-center justify-content-end bg-size-100 pb-md-2 pb-1"
                    :style="`background-image: url(${require('@/assets/img/vd/red_money.png')})`"
                    style="font-size: 12px"
                  >
                    <div>剩余数量</div>
                    <div>{{ item.num }}</div>
                  </div>
                  <div class="ml-2" style="font-size: 13px">
                    <div class="mb-2">{{ item.title }}</div>
                    <div class="mb-2">参与条件：{{ item.briefe }}</div>
                    <div class="mb-2">结束时间：{{ item.end_time }}</div>
                  </div>
                </div>
                <div
                  @click="OpenRebBack(1, item)"
                  class="cursor-pointer immediatelyShopping-pk text-center px-4"
                >
                  抢红包
                </div>
              </div>
            </div>
            <div
              @click="redPacket(0)"
              class="position-absolute"
              style="right: 0; top: 0"
            >
              <i
                class="iconfont icon-guanbi-02-02 text-white"
                style="font-size: 30px"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sUserAgent from "@/assets/js/common.js";
import { mapState } from "vuex";
import $api from "@/api/vd/index";
import TaskIn from "@/views/TaskIn/index";

export default {
  name: "silde",
  components: {
    TaskIn,
  },
  data() {
    return {
      immediatelyShopping: false,
      QQ_qr_code: "",
      QQ_qr_code_is_show: false,
      qq_group_number: 123456789,
      packet: "",
      redList: [],
      silde: 0,
      isShowSidle: false,
      sidleSum: 0,
    };
  },
  computed: {
    ...mapState(["web_info"]),
    sUserAgent() {
      return sUserAgent.sUserAgent();
    },
  },

  mounted() {
    this.init();
    this.QQ_qr_code = this.$store.state.web_info.qq_group_qr_code;
    this.qq_group_number = this.$store.state.web_info.qq_group_number;
    window.addEventListener("scroll", this.winScroll);
  },
  watch: {
    sidleSum(n, l) {
      if (l < n) {
        this.isShowSidle = true;
      } else {
        this.isShowSidle = false;
      }
    },
  },
  methods: {
    winScroll() {
      let top = Math.floor(
        document.documentElement.scrollTop || document.body.scrollTop
      );
      this.sidleSum = top;
    },
    recharge() {
      this.$router.push("/user/recharge");
    },
    init() {
      this.getredList();
      if (this.sUserAgent == "phone") {
        this.silde = -55;
        this.silde = 0;
      } else {
        this.silde = 0;
      }
    },
    keFu() {
      window.open(
        "https://tb.53kf.com/code/client/5d2e97394292cbd902a6fa892620374d4/1",
        "_blank"
      );
    },
    QQQqun() {
      window.open(
        "https://qm.qq.com/cgi-bin/qm/qr?k=g_qIp7BPVgL4erS3Q3p5-kWiRu8lnALx&jump_from=webapi&authKey=DcjNczbGACxoebTZF3ffcLXa8SiQQs+vSaobVxsuPJDnTjCjYtE3NZX/24McpssM",
        "_blank"
      );
    },
    // 跳转VIP
    memberVip() {
      this.$router.push({ name: "memberVip" });
    },
    // 隐藏显示
    sildeOff() {
      if (this.silde === 0) {
        if (this.sUserAgent == "phone") {
          this.silde = -40;
        } else {
          this.silde = -50;
        }
      } else {
        this.silde = 0;
      }
    },
    // 返回顶部
    backTop() {
      document.getElementById("content").scrollTo(0, 0);
    },
    // 打开红包
    redPacket(is) {
      if (is) {
        this.immediatelyShopping = true;
      } else {
        this.immediatelyShopping = false;
      }
    },
    QQ() {
      this.QQ_qr_code_is_show = !this.QQ_qr_code_is_show;
      setTimeout(() => {
        this.QQ_qr_code_is_show = false;
      }, 5000);
    },
    // 渲染列表
    async getredList() {
      let { data, code } = await $api.getRebBackList();
      if (code != 200) return;
      this.redList = data;
    },
    // 打开红包
    async OpenRebBack(sum, item) {
      let redOpen = {};
      if (sum == 1) {
        redOpen = {
          type: sum,
          id: item.id,
        };
      }
      if (sum == 2) {
        redOpen = {
          type: sum,
          key: this.packet,
        };
      }
      try {
        let { data, code } = await $api.getOpenRebBack(redOpen);
        if (code != 200) return;
        this.$confirm("您领取了，价值" + data + "的红包", "领取", {
          customClass: "redPacket",
          confirmButtonText: "确定",
          type: "success",
        }).then(() => {
          this.$message({
            type: "success",
            message: "领取成功!",
          });
        });

        this.$notify({
          message: data,
          type: "success",
          position: "top-left",
        });
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.silde {
  top: 30%;
  right: 0;
  width: 50px;
  z-index: 999;
  padding: 20px 0;
  background-image: url("../assets/img/silde/Rank.png");
  background-size: 100% 100%;
  transition: all 0.5s;

  @media (max-width: 1000px) {
    top: 25%;
    font-size: 12px;
    width: 40px;
    /*display: none;*/
  }

  &-off {
    top: 40%;
    left: -20px;
    background-color: var(--main-yellow);
    width: 20px;
    height: 50px;
    font-size: 24px;
    line-height: 50px;
  }

  &-serve {
    /*border:1px solid #81cdf8;*/
    border-radius: 50px;
    margin-bottom: 0px;
    // width: 70px;
    height: 80px;

    @media (max-width: 1200px) {
      width: 40px;
      height: 60px;
      background-size: 100% 100%;
    }
  }

  &-weixin {
    /*border: 1px solid #81cdf8;*/
    right: 100%;
    border-radius: 10px;
    padding-right: 10px;
    display: none;
  }

  &-W:hover &-weixin {
    display: block;
  }
}

.immediatelyShopping {
  width: 350px;
  left: calc(50% - 175px);
  top: calc(25%);
  z-index: 999;
  background-color: #c02932;
  border-radius: 10px;

  &-list {
    height: 300px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-input {
    width: 150px;
    margin: 10px auto;
    background-color: #fffad8;
    border-radius: 5px;

    & input {
      width: 140px;
    }
  }

  &-item {
    &-img {
      width: 150px;
      height: 80px;
    }
  }

  &-pk {
    width: 100%;
    margin: 5px auto;
    background-color: #bd3840;
    padding: 10px;
    border-radius: 5px;
  }

  @media (max-width: 1200px) {
    width: 70%;
    left: 15%;
    top: calc(80px + 5%);

    &-box {
      &-con {
        &-img {
          width: 50%;
        }

        & img {
          width: 100%;
        }

        &-dis {
          margin-left: 20px !important;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
