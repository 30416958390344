import { Message } from "element-ui";
var md5 = require('md5')

let str = 'o2skins.com&MessagePush*Key'
function randomString(len = 16) {
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678';
    var maxPos = $chars.length;
    var str = '';
    for (var i = 0; i < len; i++) {
        str += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return str;
}

function md5jiami(client_id) {
    let randomStr = randomString()
    var timestamp = Date.parse(new Date())
    let tiem = timestamp.toString().substr(0, 10)
    let app_id = 'siyecao'
    let token = client_id + randomStr + tiem + app_id + str
    let md5Token = md5(token)
    return {
        randomStr,
        tiem,
        md5Token
    }
}


function CopyText({ content }) {

    content = typeof content === 'string' ? content : content.toString()

    if (!document.queryCommandSupported('copy')) {
        Message('浏览器不支持')
    }
    let textarea = document.createElement("textarea")
    textarea.value = content
    textarea.readOnly = "readOnly"
    document.body.appendChild(textarea)
    textarea.select()
    textarea.setSelectionRange(0, content.length)
    let result = document.execCommand("copy")
    if (result) {
        Message.success("复制成功~")
    } else {
        Message.success("复制失败，")
    }
    textarea.remove()
}

function sUserAgent() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
        return 'phone'
    } else {
        return 'web'
    }
}

const blobToDataURL = (blob, cd) => {
    let reader = new FileReader();
    reader.onload = function (evt) {
        let base64 = evt.target.result;
        cd(base64);
    };
    reader.readAsDataURL(blob);


};

function imageUrlBase64(url) {
    return new Promise((resolve, reject) => {
        if (url) {
            let image = new Image();
            image.crossOrigin = 'anonymous';
            image.src = url;
            image.onload = function () {
                let canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                let ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, image.width, image.height);
                var ext = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
                let base64 = canvas.toDataURL('image/' + ext);
                resolve(base64);
            }
        } else {
            reject('传入图片地址')
        }
    });
}

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export default {
    md5jiami,
    CopyText,
    sUserAgent,
    blobToDataURL,
    imageUrlBase64,
    dataURLtoFile
}
